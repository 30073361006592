import(/* webpackMode: "eager" */ "/app/app/globals.css");
import(/* webpackMode: "eager" */ "/app/components/ChatlioListeners/ChatlioListeners.tsx");
import(/* webpackMode: "eager" */ "/app/components/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/components/GlobalFunctions/GlobalFunctions.tsx");
import(/* webpackMode: "eager" */ "/app/components/HeapAnalytics/HeapAnalytics.tsx");
import(/* webpackMode: "eager" */ "/app/components/HeapData/HeapData.tsx");
import(/* webpackMode: "eager" */ "/app/components/scripts/GoogleTagManagerScript/GoogleTagManagerScript.tsx");
import(/* webpackMode: "eager" */ "/app/components/SiteNav/SiteNav.tsx");
import(/* webpackMode: "eager" */ "/app/components/SplitFactoryProvider/SplitFactoryProvider.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"variable\":\"--font-space-grotesk\",\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"space_grotesk\"}")